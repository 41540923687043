<template>
  <BaseSection id="features" class="bg-blue/10">
    <div class="w-full h-full space-y-2">
      <BaseTitle>Vous allez adorer Smartbox TV</BaseTitle>
      <hr class="mx-32 md:mx-64 text-blue" />
      <p class="text-gray-8 md:px-32">
        Vous payez probablement trop cher pour la télévision en ce moment. Avec
        le forfait
        <strong class="text-black font-semibold">Smartbox TV</strong>, vous
        aurez accès au plus gros bouquet de chaînes TV mondial et à la VOD. En
        conséquence, vous pourrez regarder vos émissions préférées, pour une
        fraction du coût réel. Inscrivez-vous maintenant et Accédez à absolument
        toute la télé, sans limites et sans vous ruiner. Vous allez apprécier la
        <strong class="text-black font-semibold">TV en illimité !</strong>
      </p>
    </div>
    <!-- FEATURES -->
    <div class="mt-8 flex flex-wrap gap-4">
      <div
        v-for="f in features"
        :key="f.icon"
        class="w-full md:w-[calc(50%-16px)] flex gap-4"
        test-class="rounded bg-blue/10 border-blue-light border p-4"
      >
        <BaseIcon :icon="f.icon" class="!text-4xl" :class="f.class" />
        <div>
          <h3 class="font-semibold text-blue-dark text-lg">{{ f.title }}</h3>
          <p v-if="f.html" class="text-sm" v-html="f.description"></p>
          <p v-else class="text-sm">{{ f.description }}</p>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <div>
        <strong class="font-semibold">SMARTBOX TV</strong> quelle bénédiction
        plus besoin de payer d'abonnements pour regarder la télévision.
      </div>
      <div>
        Obtenez un
        <a href="/contact" class="underline hover:text-red">essai gratuit</a> de
        24h de TV illimité dès aujourd'hui !
      </div>
    </div>
  </BaseSection>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      features: [
        {
          class: "text-blue",
          icon: "tv",
          title: "Chaines premium illimité + Foot",
          description:
            "Pack TV internet avec accès 24/7 à plus de 14000 chaines de télévision 6k. Comprend toutes les chaines sport, les matchs de la champions league, les chaines pour adulte, les chaînes cinéma etc...",
        },
        {
          class: "text-red",
          icon: "movie_filter",
          title: "Films et séries en VOD",
          description:
            "Grace à la vidéo à la demande SVOD Smartbox TV, vous accédez à plus de 50 000 films récents et séries télévisées en VF diffusables sur tous vos écrans et appareils multimédia .",
        },
        {
          class: "text-blue",
          icon: "ac_unit",
          title: "Stable et sans coupure",
          description:
            "Votre boîtier Smartbox TV android 6K utilise la dernière technologie de compression vidéo H265 qui vous garanti une qualité de visionnage exceptionnel et inégalable.",
        },
        {
          class: "text-red",
          icon: "subscriptions",
          title: "Plus d'abonnement TV",
          description:
            "Sans engagement, un simple forfait TV illimité à prix réduit remplace tous vos abonnements télé. Inutile de s'abonner, vous restez libre de vos choix, sans renouvellement automatique en fin de contrat.",
        },
        {
          class: "text-blue",
          icon: "live_help",
          title: "Service client dédié",
          description: `Ne restez pas sans réponse, si vous avez une question, vous pouvez nous joindre du lundi au vendredi par téléphone au
                    <a href="tel:+33756825959" class="hover:text-blue underline">07 56 82 59 59</a>.`,
          html: true,
        },
        {
          class: "text-red",
          icon: "shopping_cart",
          title: "Paiement sécurisé Paypal",
          description:
            "Smartbox TV est agréé Paypal, ce qui vous permet de payer par CB ou ou via votre solde Paypal en toute sécurité.",
        },
      ],
    };
  },
};
</script>
