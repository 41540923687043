import axios from "axios";

import { ContactService } from "./contact";
import { NewsService } from "./news";

const client = axios.create({
  baseURL: "/",
  // location.hostname === "localhost"
  //   ? "http://localhost:4001/api"
  //   : "https://jukephone-server.alwaysdata.net/api",
  headers: { "Access-Control-Allow-Origin": "*" },
});

const services = {
  contacts: new ContactService(client),
  news: new NewsService(client),
};

export default services;
