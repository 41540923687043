<template>
  <div
    v-if="display"
    class="fixed z-50 inset-0 bg-black/20 flex justify-center items-center cursor-pointer"
    @mousedown.self="$emit('close')"
  >
    <div
      class="w-4/5 md:w-2/3 lg:w-1/2 xl:w-1/3 bg-white rounded shadow-md overflow-hidden cursor-default fade-in-animation"
    >
      <div class="w-full h-16">
        <button class="m-2 hover:text-red" @click="$emit('close')">
          <BaseIcon icon="close" class="!text-4xl" />
        </button>
      </div>
      <div
        class="w-full h-[calc(100%-64px)] text-center space-y-8 p-8 flex flex-col items-center"
      >
        <h2 class="font-bold text-xl text-black">Gratuit 24h de tv illimité</h2>
        <p>
          Renseigne le numéro de téléphone portable sur lequel tu souhaites
          recevoir ton
          <strong class="font-semibold text-black">code gratuit</strong> par SMS
          et profite de la télé en illimité.
        </p>
        <BaseInput
          class="w-full"
          path="phoneNumber"
          v-model="phoneNumber"
          validator="telephone"
          placeholder="Entre ton numéro de téléphone"
          @change="$emit('change')"
        />
        <BaseButton @click="$emit('enter')">Recevoir mon code</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdModal",
  props: {
    display: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      phoneNumber: "",
    };
  },
  methods: {
    toggleBg(val) {
      document.body.style.overflow = val ? "hidden" : "auto";
    },
  },
  watch: {
    display(newValue) {
      this.toggleBg(newValue);
    },
  },
};
</script>

<style scoped>
.fade-in-animation {
  animation: fade-in 500ms linear;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
