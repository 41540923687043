const width = {
  data() {
    return {
      width: window.innerWidth,
    };
  },
  created() {
    window.addEventListener("resize", this.myEventResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventResize);
  },
  methods: {
    myEventResize() {
      this.width = window.innerWidth;
    },
  },
  computed: {
    isMobile() {
      return this.width < 768;
    },
  },
};

export default width;
