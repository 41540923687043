<template>
  <master-layout>
    <BaseSection id="contact">
      <div class="flex flex-col md:flex-row justify-between gap-8">
        <!-- GAUCHE -->

        <!-- <div class="md:w-1/3">
          <BaseTitle class="text-blue">Nous contacter</BaseTitle>
          <p>
            <span>Pour une réservation, privatisation, ou une commande :</span>
            <span>
              <a href="tel:+33756825959" class="hover:text-blue">
                <BaseIcon icon="phone" class="align-bottom" />
                <span>07 56 82 59 59</span>
              </a>
            </span>
          </p>
        </div> -->
        <!-- DROITE -->
        <!-- <div class="md:w-2/3">
          <BaseTitle class="!mb-6 flex mt-8 md:mt-0 md:justify-end">
            Se rendre chez Smartbox TV
          </BaseTitle>
          <div class="relative w-full h-96">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.612143572263!2d2.3960928157401877!3d48.86560597928819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66d91d171c693%3A0xd599e96d2426abd9!2sFeng%20Sheng!5e0!3m2!1sfr!2sfr!4v1678574821001!5m2!1sfr!2sfr"
              class="absolute inset-0 w-full h-96"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div> -->
      </div>
    </BaseSection>
  </master-layout>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style scoped></style>
