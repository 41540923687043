<template>
  <BaseSection id="packages">
    <div class="w-full h-full space-y-2">
      <BaseTitle>Forfait TV Internet à prix discount</BaseTitle>
      <hr class="mx-32 md:mx-64 text-blue" />
      <p class="text-gray-8 md:px-32">
        Souhaitez-vous continuer à payer vos abonnements TV trop cher ? Nous
        vous offrons l'accès à toutes les chaînes de télévision + la VOD à
        partir de seulement 49 euros par an !!! Fini le temps ou il fallait
        souscrire à 15 abonnements TV pour pouvoir suivre toutes les
        compétitions sportives, vive les forfaits TV illimités.
      </p>
    </div>
    <div
      class="flex flex-col md:flex-row items-center md:items-stretch gap-4 mt-8"
    >
      <div
        v-for="card in cards"
        :key="card.title"
        class="w-full border-2 border-blue-darkest rounded flex flex-col max-w-xs md:max-w-none"
      >
        <!-- HEADER -->
        <h3
          class="text-xl font-semibold text-center py-2"
          :class="
            card.highlight
              ? 'bg-blue-darkest text-white border-b-2 border-blue-darkest'
              : 'border-b-2 border-blue-darkest text-blue-darkest'
          "
        >
          {{ card.title }}
        </h3>
        <!-- BODY -->
        <div class="flex flex-col justify-between h-full">
          <div>
            <!-- PRICE -->
            <div class="py-4 flex justify-center gap-2 items-end">
              <p class="line-through">{{ card.prices.normal }}€</p>
              <p :class="{ 'text-blue': card.highlight }">
                <strong class="font-bold text-6xl">{{
                  card.prices.promo
                }}</strong
                ><span class="font-bold">€</span>
              </p>
            </div>
            <!-- FEATURES -->
            <div
              v-for="(feature, index) in card.features"
              :key="index"
              class="px-4 flex items-center gap-2"
            >
              <BaseIcon :icon="feature.icon" /><span>{{ feature.text }}</span>
            </div>
          </div>
          <!-- ACTION -->
          <div class="p-4 flex justify-center">
            <BaseButton link :to="card.action.route">{{
              card.action.text
            }}</BaseButton>
          </div>
        </div>
      </div>
    </div>
  </BaseSection>
</template>
<script>
export default {
  name: "Packages",
  data() {
    return {
      cards: [
        {
          highlight: false,
          title: "TV illimité 1 an",
          prices: {
            normal: "59",
            promo: "49",
          },
          features: [
            { icon: "check", text: "Activation immédiate" },
            { icon: "check", text: "Accès illimité 24/7" },
            { icon: "check", text: "Durée de 12 mois" },
          ],
          action: {
            route: "/",
            text: "Commander",
          },
        },
        {
          highlight: false,
          title: "TV illimité 2 ans",
          prices: {
            normal: "109",
            promo: "89",
          },
          features: [
            { icon: "check", text: "Activation immédiate" },
            { icon: "check", text: "Accès illimité 24/7" },
            { icon: "check", text: "Durée de 24 mois" },
          ],
          action: {
            route: "/",
            text: "Commander",
          },
        },
        {
          highlight: true,
          title: "Coffret Smartbox TV",
          prices: {
            normal: "159",
            promo: "129",
          },
          features: [
            { icon: "check", text: "Activation immédiate" },
            { icon: "check", text: "Accès illimité 24/7" },
            { icon: "check", text: "Durée de 12 mois" },
            { icon: "check", text: "Box android TV garantie 2 an" },
          ],
          action: {
            route: "/",
            text: "Commander",
          },
        },
      ],
    };
  },
};
</script>
