<template>
  <footer
    class="w-full bottom-0 z-40 flex justify-center bg-blue/10 py-8 md:py-16"
  >
    <div class="flex flex-col w-full max-w-6xl px-16">
      <div
        class="w-full flex flex-col items-start md:flex-row gap-8 flex-wrap md:flex-nowrap md:justify-between"
      >
        <!-- Social networks -->
        <div class="flex items-center justify-center w-full">
          <div class="flex items-center gap-2">
            <img
              src="../assets/img/logo.png"
              alt="smartbox tv logo"
              class="h-8 w-8 object-cover"
            />

            <span class="font-semibold text-blue text-lg">Smartbox TV</span>
          </div>
        </div>

        <!-- Address -->
        <div class="flex justify-center w-full">
          <a
            href="https://www.instagram.com/tv.illimite/"
            target="_blank"
            rel="noopener noreferrer"
            class="flex items-center gap-2 cursor-pointer hover:text-blue text-lg"
          >
            <BaseIcon icon="ig" class="w-6 h-6" custom />
            <span>Suivez notre équipe !</span>
          </a>
        </div>
        <!-- Contact -->
        <div class="flex items-center justify-center w-full">
          <a
            href="tel:+33756825959"
            class="flex items-center gap-2 hover:text-blue font-semibold text-lg"
          >
            <BaseIcon icon="phone" />
            <span>07 56 82 59 59</span>
          </a>
        </div>
      </div>
      <div class="flex justify-center text-center text-gray-5 text-sm mt-8">
        &#169; 2023 Smartbox TV. Tous droits réservés
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
