<template>
  <master-layout>
    <Home />
    <Channels />
    <Information />
  </master-layout>
</template>

<script>
import Home from "@/components/About/Home";
import Channels from "../components/Landing/Channels";
import Information from "../components/About/Information";

export default {
  name: "About",
  components: {
    Home,
    Channels,
    Information,
  },
};
</script>
