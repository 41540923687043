<template>
  <router-link
    :to="href"
    class="px-2 md:transition-colors font-medium hover:text-blue hover:bg-blue/10 cursor-pointer rounded"
    :class="[active ? 'active' : '']"
  >
    {{ name }}
  </router-link>
</template>
<script>
export default {
  name: "NavItem",
  props: {
    href: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.active {
  color: theme("colors.blue.DEFAULT") !important;
  position: relative;
}

.active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 8px;
  height: 1px;
  width: calc(100% - 16px);
  background-color: theme("colors.blue.DEFAULT");
}
</style>
