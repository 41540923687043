<template>
  <BaseSection id="home" class="pt-16 md:pt-0">
    <main
      class="flex flex-col md:flex-row gap-8 items-center justify-center relative w-full h-[calc(100vh-192px-64px)] md:h-[calc(100vh-192px-128px)]"
    >
      <!-- RIGHT -->
      <div class="w-full md:w-3/5 space-y-4">
        <h1 id="title" class="text-blue-dark font-bold text-2xl">
          SMARTBOX TV - La télé en illimité
        </h1>
        <h3 id="subtitle" class="text-blue font-semibold text-lg">
          + de 14000 chaines TV et VOD en illimité
        </h3>
        <p id="description" class="text-gray-8">
          Avec le forfait
          <strong class="text-black font-semibold">Smartbox TV</strong> régalez
          vous en accédant 24/7 à la
          <strong class="text-black font-semibold">télé en illimité</strong>.
          Recevez toutes les chaines de télévision payantes et gratuites du
          monde entier + VOD. Chaîne sport, enfant, adulte, documentaire, cinéma
          etc… Le tout pour seulement 49€/AN. Fonctionne sur tous les
          téléviseurs, ordinateurs, mobiles et tablettes.
        </p>
        <div class="space-y-4">
          <p class="text-dark text-4xl font-bold tracking-tight">
            <span class="text-base font-semibold tracking-wide"
              >À partir de</span
            >
            49€
            <span class="text-base font-semibold tracking-wide">Par An</span>
            <span class="ml-2 text-sm font-semibold tracking-wide line-through">
              59€
            </span>
          </p>
          <BaseButton
            link
            to="/forfait-smartbox-tv-12-mois"
            class="px-4 py-2 bg-blue text-white rounded-lg flex items-center"
          >
            <div class="flex items-center justify-between w-full">
              Commencez maintenant
              <BaseIcon icon="arrow_forward" />
            </div>
          </BaseButton>
        </div>
      </div>
      <!-- LEFT -->
      <div class="w-1/2 md:w-3/5 hidden xs:block">
        <img
          class="w-full h-full aspect-square"
          src="../../assets/img/essai-tv-illimité.jpg"
          title="essai tv illimité"
          alt="essai tv illimité"
        />
      </div>
    </main>
  </BaseSection>
</template>
<script>
export default {
  name: "Home",
};
</script>

<style></style>
