<template>
  <BaseSection id="channels">
    <div class="flex flex-row justify-between items-center h-16 md:h-32">
      <div v-for="c in channels" :key="c">
        <img :src="require(`../../assets/img/channels/${c}.png`)" :alt="c" />
      </div>
    </div>
  </BaseSection>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      channels: [
        "bein-sports",
        "canal-plus",
        "disney-plus",
        "hbo",
        "netflix",
        "ocs",
        "prime-video",
        "rmc-sport",
      ],
    };
  },
};
</script>
