<template>
  <master-layout>
    <Home />
    <Channels />
    <Features />
    <Deals />
    <Choose />
    <Packages />
  </master-layout>
</template>

<script>
import Home from "../components/Landing/Home";
import Channels from "../components/Landing/Channels";
import Features from "../components/Landing/Features";
import Deals from "../components/Landing/Deals";
import Choose from "../components/Landing/Choose";
import Packages from "../components/Landing/Packages";

export default {
  name: "Landing",
  components: {
    Home,
    Channels,
    Features,
    Deals,
    Choose,
    Packages,
  },
};
</script>
