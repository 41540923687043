<template>
  <BaseSection id="home">
    <main
      class="flex flex-col md:flex-row gap-8 items-center justify-center relative w-full"
    >
      <!-- RIGHT -->
      <div class="w-full md:w-1/2 space-y-4">
        <h1 id="title" class="text-blue-dark font-bold text-2xl">
          COMMENT UTILISER UNE SMART BOX
        </h1>
        <h3 id="subtitle" class="text-blue font-semibold text-lg">
          La box TV la plus simple à installer
        </h3>
        <p id="description" class="text-gray-8">
          Un cable, une connexion internet et un code, c'est tout ce dont vous
          avez besoin pour installer et utiliser votre smart box. Le coffret
          Smartbox TV transforme n'importe quel téléviseur en centre multimédia
          smart TV avec accès illimité à toutes les chaînes de télévision du
          monde et aux derniers films et séries en VOD.
        </p>
      </div>
      <!-- LEFT -->
      <div class="w-1/2 hidden xs:block">
        <img
          class="w-full h-full aspect-square"
          src="../../assets/img/tv-chaînes-cinema-foot-adulte.jpg"
          title="tv chaînes cinema foot adulte"
          alt="tv chaînes cinema foot adulte"
        />
      </div>
    </main>
  </BaseSection>
</template>
<script>
export default {
  name: "Home",
};
</script>
