<template>
  <BaseSection id="choose" class="bg-blue/10">
    <div class="w-full h-full space-y-2">
      <BaseTitle>Pourquoi choisir Smartbox TV</BaseTitle>
      <hr class="mx-32 md:mx-64 text-blue" />
      <p class="text-gray-8 md:px-32">
        D'une simple pression sur le bouton de votre télécommande, nous vous
        donnerons accès à
        <strong class="text-black font-semibold"
          >toutes les chaînes de télévision</strong
        >
        et au service de VOD . Ne loupez plus jamais vos programmes télévisés
        préférés à cause d'un abonnement mensuel! Passez immédiatement à la télé
        en illimité.
      </p>
    </div>
    <div class="flex flex-col md:flex-row gap-8 mt-8">
      <!-- LEFT -->
      <div class="w-[calc(100%-16px)] space-y-8">
        <p>
          <strong class="text-black font-semibold">
            Smartbox TV est un décodeur télé intelligent
          </strong>
          qui débride l'accès à toutes les chaînes de télévision payantes et
          chaînes gratuites en ne payant qu'un seul et unique forfait à bas
          prix. Avec Smartbox TV et un accès à internet, vous accédez à la télé
          en illimité.
        </p>
        <p>
          <strong class="text-black font-semibold">
            Vidéo à la demande :
          </strong>
          Votre Smartbox vous permet de visionner plus de 50000 films et séries
          en VOD. Remis à jour chaque semaine avec les dernières sorties.
        </p>
        <p>
          <strong class="text-black font-semibold">
            TV internet pas cher :
          </strong>
          Mieux qu'un abonnement TV internet, le forfait Smartbox TV vous offre
          tous les bouquets TV ainsi que la vidéo à la demande pour un prix
          imbattable !
        </p>
        <p>
          <strong class="text-black font-semibold">
            Télé haute définition :
          </strong>
          Avec sa technologie ultra HD, votre smart box TV 6K diffuse +90% des
          flux télévisés en full HD même si vous n'avez pas la fibre internet.
        </p>
        <p>
          <strong class="text-black font-semibold">
            Smartbox activation :
          </strong>
          L'activation de votre Smartbox forfait TV illimité se fait en moins de
          24h. Vous profitez immédiatement de la télévision sans limite et de
          vos emissions en direct ou en replay. Accéder à la télévision
          illimité, c'est facile et rapide.
        </p>
      </div>
      <!-- RIGHT -->
      <div class="w-[calc(100%-16px)]" role="tablist">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="border-b border-blue"
        >
          <h3
            role="tab"
            :data-tab="index"
            :aria-expanded="item.open"
            :aria-selected="item.open"
            class="flex items-center text-lg font-semibold text-blue cursor-pointer hover:text-blue-dark h-16"
            :class="{ 'text-blue-dark': item.open }"
            @click="item.open = !item.open"
          >
            <BaseIcon
              aria-hidden="true"
              icon="arrow_right"
              class="!text-4xl"
              :class="{ 'rotate-90': item.open }"
            />
            {{ item.title }}
          </h3>
          <p
            :data-tab="index"
            role="tabpanel"
            class="max-h-0 overflow-hidden transition-all duration-300 pl-9"
            :class="{ 'max-h-[500px] pb-2': item.open }"
          >
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>
  </BaseSection>
</template>
<script>
export default {
  name: "Choose",
  data() {
    return {
      items: [
        {
          title: "+ De 14.000 chaines Françaises et internationales",
          description:
            "La meilleure façon de regarder la télévision en direct sur tous vos appareils : télévision, ordinateur, tablette, téléphone. Smartbox TV vous permet de visionner toutes les chaines de TV sans frais mensuels ni frais cachés. Pas de contrat, pas d'abonnements et La meilleure partie est que tout est inclus dans un seul forfait pour un paiement unique à prix réduit de 49€ par an ! Il ne vous reste plus qu'à zapper sur vos chaînes de télévision favorites. ",
          open: false,
        },
        {
          title: "+ De 50.000 films et séries VOD",
          description:
            "La meilleure façon de regarder la télévision en direct sur tous vos appareils : télévision, ordinateur, tablette, téléphone. Smartbox TV vous permet de visionner toutes les chaines de TV sans frais mensuels ni frais cachés. Pas de contrat, pas d'abonnements et La meilleure partie est que tout est inclus dans un seul forfait pour un paiement unique à prix réduit de 49€ par an ! Il ne vous reste plus qu'à zapper sur vos chaînes de télévision favorites. ",
          open: false,
        },
        {
          title: "Télé en illimité pour toute la famille",
          description:
            "Smartbox TV offre une expérience de télévision personnalisée à chaque membre de la famille. Ainsi chacun peut choisir de regarder son programme tv favoris et adaptés à ses propres intérêts. Fini le temps des combats pour la télécommande ! Madame pourra regarder ses séries, monsieur suivra les matchs de foot en direct et les enfants auront accès à tous les programmes jeunesse ou ciné.",
          open: false,
        },
        {
          title: "Match en direct et sports",
          description:
            "Vous pouvez regarder tous vos matchs de foot en direct mais pas seulement. Avec Smartbox TV, vous allez aussi pouvoir suivre toutes les chaines sportives et voir tout ce qui vous passionne boxe, formule  1, turf, golf etc…",
          open: false,
        },
        {
          title: "Chaînes pour adulte en anonyme",
          description:
            "Vous êtes nombreux à vouloir pouvoir regarder librement du contenu pour adulte sans que cela ne se sache. Chez Smartbox TV, vous avez toutes les chaînes X sans que cela n'apparaisse sur vos relevés bancaires. Nous pensons que ni votre banquier, ni votre famille n'a besoin de savoir ce que vous aimez regarder. ",
          open: false,
        },
        {
          title: "Qualité vidéo 6K",
          description:
            "Vous pouvez désormais profiter de tous vos programmes télévisés préférés en qualité 6K sur grand écran dans le confort de votre salon avec notre forfait Smartbox tv abordable et efficace. Profitez d'une réception cristalline et d'une grande variété de programmes pour tous les goûts en full HD.",
          open: false,
        },
        {
          title: "Box facile à installer",
          description:
            "Votre smart box est facile à installer et à utiliser, elle se connecte directement à votre téléviseur via un câble HDMI et au wifi de votre box internet. Nous offrons un service de pré-installation unique, permettant à nos clients de regarder la tv en illimité sans aucunes restrictions. Vous n'avez ni parabole ou antenne à installer ni satellite à trouver. En clair, une simple connexion internet adsl ou wifi 4G suffit pour recevoir la télé en illimité depuis n'importe quel endroit de la planète. ",
          open: false,
        },
        {
          title: "Garantie 2 ans",
          description:
            "Votre smart box android 6K est faite pour durer. Parce que nous savons que louper un programme tv à cause d'une défaillance technique est frustrant, nous avons tout mis en oeuvre pour que cela n'arrive jamais. Votre décodeur TV dispose d'une garantie de 2 ans, donc en cas de panne nous vous le changeons immédiatement.",
          open: false,
        },
        {
          title: "Des économies toute l'année",
          description:
            "Nous sommes l'un des services de télévision à la croissance la plus rapide sur le marché, offrant une solution qui fonctionne sans parabole ou installation couteuse. Abordable le forfait Smartbox TV vous fait gagner +800 € d'économies sur vos  abonnements télévisuels. Notre service est accessible à tous, quel que soit votre emplacement ou votre fournisseur internet FAI. Nous offrons un service premium avec l'offre TV la plus large et la VOD pour seulement 49€/an. Un beau cadeau à offrir ou à s'offrir.",
          open: false,
        },
      ],
    };
  },
};
</script>
