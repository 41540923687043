import { createRouter, createWebHistory } from "vue-router";
import scrollBehavior from "./scrollBehavior";
import Landing from "../pages/Landing.vue";
import About from "../pages/About.vue";
import Contact from "../pages/Contact.vue";
import Smartbox from "../pages/Smartbox.vue";
import Package from "../pages/Package.vue";

const routes = [
  {
    path: "/",
    component: Landing,
  },
  {
    path: "/à-propos",
    component: About,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/coffret-smartbox-tv",
    component: Smartbox,
  },
  {
    path: "/forfait-smartbox-tv-12-mois",
    component: Package,
  },
  //unknown route
  {
    path: "/:path(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: scrollBehavior,
});

export default router;
