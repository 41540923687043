<template>
  <div
    class="fixed bottom-4 right-4 transition-opacity z-50"
    :class="[scrollTop ? 'opacity-100' : 'opacity-0']"
  >
    <!-- <BaseButton size="mini" @click="scrollToTop">
      <BaseIcon icon="chevron-up" color="FFFFFF" size="2rem" />
    </BaseButton> -->
  </div>
</template>
<script>
export default {
  name: "ScrollButton",
  props: {
    scrollTop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
