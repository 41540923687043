<template>
  <div class="w-full">
    <!-- FIXED -->
    <header
      class="w-full h-16 fixed top-0 z-40 flex justify-center bg-blue-darkest text-white"
      :class="[scrollHeader ? 'shadow-md' : '']"
    >
      <div
        class="w-full max-w-6xl h-16 px-4 md:px-16 flex items-center justify-between"
      >
        <!-- left navbar -->
        <div class="flex items-center gap-1">
          <router-link
            to="/"
            class="flex items-center hover:text-blue cursor-pointer rounded"
          >
            <img
              src="../assets/img/logo-white.png"
              alt="smartbox tv logo"
              class="h-12 w-12 mb-1"
            />
            <span class="px-4 text-white font-bold italic text-xl">
              {{ "Smartbox TV" }}
            </span>
          </router-link>
          <nav class="hidden md:flex items-center gap-2" role="navigation">
            <NavItem
              v-for="item in menu"
              :key="item.href"
              :href="item.href"
              :name="item.name"
              :active="item.href === activeNav"
            />
          </nav>
        </div>

        <!-- right navbar -->
        <div class="flex gap-4">
          <a
            href="tel:+33756825959"
            class="hidden xs:flex items-center gap-2 hover:text-blue"
          >
            <BaseIcon icon="phone" />
            <span>07 56 82 59 59</span>
          </a>
          <!-- mobile nav action -->
          <button
            v-if="isMobile"
            class="flex md:hidden items-center cursor-pointer"
            aria-label="Afficher le menu"
            @click="nav = !nav"
          >
            <BaseIcon v-if="nav" icon="close" size="20px" role="presentation" />
            <BaseIcon v-else icon="menu" size="20px" role="presentation" />
          </button>
        </div>
      </div>
    </header>
    <!-- PLACEHOLDER -->
    <div class="w-full h-16"></div>
    <!-- mobile dropdown -->
    <div
      v-if="isMobile"
      class="border-white fixed z-50 w-full top-16 overflow-hidden transition-height duration-300 rounded-b-xl bg-blue-darkest text-white shadow-md"
      :class="[nav ? 'max-h-full' : 'max-h-0']"
      v-click-outside="closeNav"
    >
      <nav
        class="flex flex-col items-center w-full gap-2 py-4 z-50 text-xl"
        role="navigation"
        aria-hidden="true"
      >
        <NavItem
          v-for="item in menu"
          :key="item.href"
          :href="item.href"
          :name="item.name"
          :active="item.href === activeNav"
          @click.native="closeNav"
        />
      </nav>
    </div>
  </div>
</template>

<script>
import NavItem from "@/layout/NavItem";
import width from "@/mixins/width";

export default {
  mixins: [width],
  name: "Header",
  components: {
    NavItem,
  },
  props: {
    scrollHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nav: false,
    };
  },
  computed: {
    activeNav() {
      return this.$route.path;
    },
  },
  methods: {
    closeNav() {
      this.nav = false;
    },
  },
  data() {
    return {
      dropDownOpen: false,
      menu: [
        { href: "/à-propos", name: "À propos" },
        { href: "/contact", name: "Contact" },
      ],
    };
  },
};
</script>
