<template>
  <BaseSection id="information" class="bg-blue/10">
    <div class="w-full h-full space-y-2">
      <BaseTitle>Tout savoir sur Smartbox TV</BaseTitle>
      <hr class="mx-32 md:mx-64 text-blue" />
      <p class="text-gray-8 md:px-32">
        Smartbox TV est un boîtier intelligent qui s'auto paramètre de façon
        autonome. Inutile d'être ingénieur, la box peut être facilement
        installée par un enfant de 10 ans. Pas de frustration, tout est inclus
        dans votre coffret Smartbox TV, le boîtier télé, le forfait TV illimité
        12 mois, la prise péritel et nous avons également pensé à vous mettre
        les piles pour la télécommande. Après réception de votre box TV android,
        il ne vous reste plus qu'à la déballer et la brancher pour pouvoir
        profiter pleinement de la télévision en illimité. Compatible avec toutes
        les télévisions (Philips, Samsung, Apple TV etc…)
      </p>
    </div>
    <div class="space-y-4 mt-8">
      <div v-for="(item, i) in items" :key="i" class="space-y-4">
        <h3 class="text-blue font-bold text-xl">{{ item.title }}</h3>
        <p v-for="(d, j) in item.descriptions" :key="j">{{ d }}</p>
      </div>
    </div>
  </BaseSection>
</template>

<script>
export default {
  name: "Info",
  data() {
    return {
      items: [
        {
          title: "C'est quoi une smart box ?",
          descriptions: [
            "Une smart box est un décodeur TV android, dont le rôle est de permettre à n'importe quels téléviseurs de pouvoir communiquer avec un serveur internet, tout comme le ferais un ordinateur portable, une tablette ou encore un smartphone. Bien que ces boîtiers soient construits sous android, ils restent parfaitement compatibles avec les systèmes d'exploitation IOS et Linux. En clair, une smart box ajoute des fonctionnalités et des commandes intelligentes à votre téléviseur.",
            "L'avantage principal est de pouvoir ainsi recevoir et visionner toutes les chaînes de télévision et les vidéos à la demande sur votre télé, en couplant la box avec un forfait TV internet à bas prix. Grâce à la box vous avez également accès au réseau internet et au play store Google, vous pouvez donc télécharger et utiliser vos applications android, jeux et réseaux sociaux sur votre écran de télé de la même manière que vous le ferriez sur votre téléphone portable.",
          ],
        },
        {
          title: "Comment fonctionne un boîtier android Smartbox TV ?",
          descriptions: [
            "Contrairement à une smart box android classique, les boîtiers commercialisés sur le site internet Smartbox TV sont configurés d'origine pour vous permettre de profiter immédiatement de la télé en illimité. Pas d'applications à télécharger ou de paramétrages à effectuer, votre smart box est prête et fonctionnelle dès sa sortie du carton.",
            "Votre box TV se branche par l'arrière de votre téléviseur à l'aide d'un cable HDMI inclus et se connecte à internet soit via un cable réseau Ethernet (RJ45) ou en wifi. C'est vraiment le boîtier android TV  le plus simple à installer et à utiliser. ",
          ],
        },
        {
          title:
            "Combien coûte une smart box et comment choisir la meilleure ?",
          descriptions: [
            "Le prix d'une box android TV est très variable, cela s'étale de 40€ à 250€ selon les caractéristiques techniques de l'appareil. Il est donc souvent difficile de s'y retrouver et de faire le bon choix. Donc avant d'acheter une smart box, il est indispensable de bien réfléchir à l'usage que vous souhaitez en faire. Cependant 80% des utilisateurs utilisent une android box TV que pour un usage média, regarder la télé, voir des films en VOD, jouer à des jeux basiques, visionner des photos ou encore pour télécharger des applis sur le Google play store, dans ce cas inutile de dépenser plus de 100€. Les box plus hauts de gammes sont équipées d'un processeur et d'une carte graphique plus puissante, ces éléments n'apportent un plus que pour le gaming car les jeux en 3d demandent plus de ressources que la lecture d'une vidéo 4k full-HD.",
            "Attention aux TV box android vendus en dessous de 70€, il est souvent préférable de rajouter 10€ plutôt que d'acheter une smart box décevante. Il n'y a pas de magie, un prix plus bas signifie que la fabricant à fait des concessions sur la qualité des pièces électroniques. Ces box offrent un niveau de compression des images trop bas, manquent de connectiques de sortie et l'accès à internet est instable. Cela se ressent immédiatement et se traduit par une image de mauvaise qualité et qui régulièrement se fige à l'écran.",
          ],
        },
        {
          title: "Pourquoi choisir la TV box android de Smartbox TV ?",
          descriptions: [
            "Tout d'abord pour la qualité du produit, nous avons sélectionnés la meilleure smart box grand public du marché. Une box TV quad-core, garantie 2 ans avec une connexion internet très stable un son Dolby, toutes les connectiques dont un lecteur de carte mémoire et surtout pour ses images vidéos 6K ultra HD.",
            "Mais aussi pour sa simplicité d'utilisation, votre box est plug and play, son apk android emporte d'origine toutes les apps pour que vous n'ayez rien d'autre à faire que de la brancher pour en profiter.",
            "Et surtout pour son rapport qualité prix imbattable, pour 129 euros tout est inclus, la smart box et ses accessoires, le forfait TV internet pour 1 an de TV en illimité d'une valeur de 49€ et les frais de ports qui sont généralement de 9 euros.",
          ],
        },
      ],
    };
  },
};
</script>
