<template>
  <master-layout>
    <BaseSection id="smartbox">
      <div class="flex flex-col md:flex-row justify-between gap-8"></div>
    </BaseSection>
  </master-layout>
</template>

<script>
export default {
  name: "Smartbox",
};
</script>

<style lang="scss" scoped></style>
