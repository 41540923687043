<template>
  <div
    class="w-full w-max-[100vw] bg-body text-gray-9 flex flex-wrap flex-col items-center overflow-x-hidden text-sm md:text-base"
  >
    <AdModal :display="modal" @close="modal = false" />

    <Header :scroll-header="scrollHeader" />
    <div class="w-full min-h-screen">
      <slot></slot>
    </div>
    <ScrollButton :scroll-top="scrollTop" />
    <Footer />
  </div>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";
import ScrollButton from "@/layout/ScrollButton.vue";
import AdModal from "../components/Landing/AdModal.vue";

export default {
  name: "MasterLayout",

  components: {
    Header,
    Footer,
    ScrollButton,
    AdModal,
  },
  data() {
    return { scrollHeader: false, scrollTop: false, modal: false };
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
    setTimeout(() => {
      if (this.$route.path === "/") this.modal = true;
    }, 2000);
  },
  methods: {
    handleScroll() {
      const scroll = window.scrollY;
      this.scrollHeader = scroll >= 200;
      this.scrollTop = scroll >= 560;
    },
  },
};
</script>
