<template>
  <BaseSection id="deals">
    <div class="w-full h-full space-y-2">
      <BaseTitle>Les offres Smartbox TV</BaseTitle>
      <hr class="mx-32 md:mx-64 text-blue" />
      <p class="text-gray-8 md:px-32">
        <strong class="text-black font-semibold">OUI</strong>, vous pouvez
        regarder tous vos films et séries préférés sur Smartbox TV 14000 chaînes
        de télé 50000 films et séries en VOD pour 49€ l'an. Fonctionne sur tous
        vos appareils télévisions, smartphones, tablettes et c'est compatible
        avec tous les systèmes d'exploitation Android, IOS, Linux. Testez notre
        service de télé illimité gratuitement pendant 24 heures et achetez le
        seulement si vous êtes 100% satisfait.
      </p>
    </div>
    <!-- FEATURES -->
    <div class="mt-8 flex flex-wrap gap-8">
      <div
        v-for="(c, i) in cards"
        :key="i"
        class="flex flex-col gap-4 justify-between w-full md:w-[calc(50%-32px)] p-4 rounded-lg bg-white shadow hover:shadow-lg transition-shadow"
      >
        <div class="space-y-4">
          <h3 class="font-semibold text-blue-dark text-center text-xl">
            {{ c.title }}
          </h3>
          <p v-for="(d, i) in c.descriptions" :key="i" v-html="d"></p>

          <img
            class="mx-auto w-2/3 aspect-square"
            :src="require(`../../assets/img/${c.image.url}.png`)"
            :alt="c.image.alt"
          />
          <p
            v-for="(item, i) in c.items"
            :key="i"
            class="flex items-center gap-2"
          >
            <BaseIcon icon="check" class="text-blue" />
            <span v-html="item"></span>
          </p>
        </div>
        <div class="flex justify-center justify-self-end">
          <BaseButton
            link
            :to="c.button.route"
            class="px-4 py-2 bg-blue text-white rounded-lg flex items-center"
          >
            <BaseIcon icon="arrow_right" />
            {{ c.button.text }}
          </BaseButton>
        </div>
      </div>
    </div>
    <div class="mt-8 text-sm space-y-2">
      <p>
        Coffret Smartbox ou forfait 12 mois ? Comment choisir le produit
        Smartbox TV idéal et adapté à vos besoins ?
      </p>
      <p>
        Si vous disposez d'un téléviseur classique, optez pour le coffret
        Smartbox TV complet, il comprend une box android qui transforme
        n'importe quel poste de télévision en smart tv intelligente. Dans votre
        cas la box tv android 6k est indispensable afin de pouvoir recevoir les
        chaînes du monde entier et la VOD.
      </p>
      <p>
        Si vous possédez une smart tv ou si vous disposez déjà d'un boîtier
        android à la maison, alors dans votre cas le forfait est suffisant pour
        accéder aux avantages incroyables de la télévision en illimité. De la
        même façon, si vous souhaitez visionner vos canaux préférés uniquement
        depuis un ordinateur, un téléphone portable, une tablette ou depuis
        n'importe quel autre appareil connecté à internet, alors le forfait est
        bien le produit qui vous convient.
      </p>
      <p>
        Comment se passe ma commande sur le site ? Choisissez votre produit,
        effectuez votre paiement sur la plateforme sécurisé Paypal. Nous
        expédions votre commande en Colissimo pour la box et par mail ou SMS
        pour le forfait TV le jour même quand c'est possible ou le premier jour
        non férié dans le cas contraire. Pour votre sécurité nous ne vous
        demanderons jamais d'inscrire vos informations personnelles sur le site,
        car c'est Paypal qui nous transmet votre adresse, votre email et votre
        nom, afin de pouvoir gérer l'expédition de votre commande. Seul votre
        numéro de téléphone portable est requis lors de la commande.
      </p>

      <div class="flex flex-col md:flex-row gap-4">
        <!-- RIGHT -->
        <div class="flex flex-col items-center justify-center space-y-2">
          <BaseIcon icon="thumb_up" class="text-blue !text-4xl" />
          <h2 class="font-semibold text-blue text-xl">La TV par internet</h2>
          <p>
            Plus de 200 millions de personnes à travers le monde ont fait le
            choix de couper le câble pour passer à la télévision par internet.
            C'est l'avenir de la TV, plus besoin d'antenne de parabole ou
            d'installation coûteuse, un nombre de chaînes illimitées, l'accès
            aux films et séries en VOD et des vidéos de meilleures qualités. Le
            tout pour un prix imbattable.
          </p>
        </div>
        <!-- LEFT -->
        <video
          class="w-full md:w-1/2 h-full"
          src="../../assets/img/smartbox-tv-chaines-illimite.mp4"
          controls=""
          controlslist="nodownload"
        ></video>
      </div>
    </div>
  </BaseSection>
</template>
<script>
export default {
  name: "Deals",
  data() {
    return {
      cards: [
        {
          image: {
            url: "boîtier",
            alt: "boîtier iptv Q",
          },
          title: "Coffret Smartbox TV + Forfait 12 Mois",
          descriptions: [
            "Smart box TV android Q+ 6K  5g version ULTIMATE uhd, livré prêt à l'emploi + carte d'activation Smartbox TV illimité toutes les chaines pendant 1 an + VOD. Fonctionne sur toutes les télés équipées d'une prise HDMI.",
            "Votre coffret contient : La smart box android TV + le forfait smartbox TV illimité 1 an + un cable HDMI + la télécommande + piles incluses.",
            "Livraison en 48/72H - Frais de port gratuit France et Europe.",
          ],
          items: [
            "Smart box TV 6K",
            "Meilleure box tv android installation facile",
            "Livraison rapide et gratuite",
            `30€ de remise soit 129€ au lieu de <strong class="line-through">159€</strong>`,
          ],
          button: {
            text: "Acheter 129€",
            route: "/coffret-smartbox-tv",
          },
        },
        {
          image: {
            url: "bouquet",
            alt: "bouquet chaînes",
          },
          title: "Forfait Smartbox TV - 12 Mois",
          descriptions: [
            "Forfait Smartbox TV illimité 12 mois. Accès à toutes les chaines de télévision France et monde. Compatible avec toutes les box android tv , smart tv, smartphones, pc, tablettes.",
            "Accès illimité à +14000 chaines de TV et +50000 films et séries en VOD. Comprend toutes les chaines de sport, cinéma, enfant adulte, actualité…",
            "Livraison par SMS le jour même - Utilisable immédiatement",
          ],
          items: [
            "+14000 chaines dont les chaines sport",
            "Image et son d'une incroyable qualité",
            "+50000 films et séries en VOD",
            `10€ de remise soit 49€ au lieu de <strong class="line-through">59€</strong>`,
          ],
          button: {
            text: "Acheter 49€",
            route: "/forfait-smartbox-tv-12-mois",
          },
        },
      ],
    };
  },
};
</script>
